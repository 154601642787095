import React from 'react'
import Link from '~components/Link'
import Button from '~components/Button'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import './index.scss';

const SplitIllustration = ({ data, isTextLeft = false, isFullWidth = false, isExternal = false, isPreview }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-25%",
    threshold: 0
  })

  const splitIllustrationContent = (content) => {
    return (
      <div className="split-illustration__content">
        { content.title &&
          <div className="split-illustration__content__title">{content.title}</div>
        }
        { content.description &&
          <div className="split-illustration__content__description">{content.description}</div>
        }
        { content.button &&
          <Button data={content.button} isPreview={isPreview} />
        }
      </div>
    )
  }

  return (
    <div
      className={
        `split-illustration
        ${isFullWidth ? 'split-illustration--full-width' : ''}
        ${isTextLeft ? 'split-illustration--image-right' : ''}`
      }
      ref={componentRef}
    >
        <div className="split-illustration__image--wrapper">
          { isExternal ?
            <a href={data.content.button.url} target="_blank" rel="noopener noreferrer">
              <img className="split-illustration__image" src={data.image} alt={data.image_alt} />
            </a> :
            <Link to={data.content.button.url} isPreview={isPreview}>
              <img className="split-illustration__image" src={data.image} alt={data.image_alt} />
            </Link>
          }
        </div>
      { data.content && splitIllustrationContent(data.content)}
    </div>
  )
}

export default SplitIllustration
