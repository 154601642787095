import React from 'react'

import Header from '~components/Header'
import Footer from '~components/Footer'

import Button from '~components/Button'
import Ruler from '~components/Ruler'
import DoubleRuler from '~components/DoubleRuler'
import Card from '~components/Card'
import SplitIllustration from '~components/SplitIllustration'

// TODO: put all case study sections in here and remove anything else
const HomePageWrap = ({ data, isPreview }) => {
  return (
    <>
      <Header isPreview={isPreview} />
      <Button data={data.button} isPreview={isPreview} />
      <Button data={data.buttonTwo} isPreview={isPreview} />
      <Ruler data={data.ruler} isPreview={isPreview} />
      <Ruler data={data.rulerTwo} isPreview={isPreview} />
      <Ruler data={data.rulerThree} isPreview={isPreview} />
      <DoubleRuler data={data.doubleRuler} isPreview={isPreview} />
      <Card data={data.card} isPreview={isPreview} />
      <SplitIllustration data={data.splitIllustration} isPreview={isPreview} />
      <SplitIllustration data={data.splitIllustrationTwo} isPreview={isPreview} />
      <Footer isPreview={isPreview} />
    </>
  )
}

export default HomePageWrap
