import React from 'react'
import PropTypes from 'prop-types'

import Ruler from '~components/Ruler'

import './index.scss'

const Card = ({ data, isPreview }) => {
  return (
    <div className="card">
      <div className="card__image--wrapper">
        <img className="card__image" alt="" src={data.image_url} />
      </div>
      { data.description ? <p className="card__description">{data.description}</p> : null }
      { data.ruler ? <Ruler data={data.ruler} isPreview={isPreview}/> : null }
    </div>
  )
}

Card.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    image_url: PropTypes.string,
    ruler: PropTypes.object
  }),
}

export default Card
