import React from 'react'

import Layout from '~global/Layout'
import ComponentsLibraryPageWrap from '~pageWraps/ComponentsLibraryPageWrap'

import data from '../content/content-library.yaml'

const Home = () => {
  return (
    <Layout>
      <ComponentsLibraryPageWrap data={data}/>
    </Layout>
  )
}

export default Home
